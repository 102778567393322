#page-orders {
    width: 100%;
    .order-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $borderColor;
      h2 {
        font-size: 24px;
        margin-bottom: 0;
      }
      
    }
    .order-table {
        table {
            tbody {
            tr{
                height: 60px;
                td{
                    vertical-align: middle;
                }
            }
    
            .status {
              background-color: #7ace4c;
              padding: 6px 20px;
              border: none;
              color: #fff;
              text-transform: uppercase;
              font-size: 16px;
            }
            .delivery {
              background-color: #f33155;
              padding: 6px 20px;
              border: none;
              color: #fff;
              text-transform: uppercase;
              font-size: 16px;
            }
          }
        }
      }
}