#page-home {
  width: 100%;
  .detail-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 40px;
    .single-card {
      // border: 1px solid $borderColor;
      background-color: #edf1f5;
      padding: 20px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      transition: box-shadow 0.5s ease;
      cursor: pointer;
      &:hover {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
      .detail {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: center;
        svg {
          font-size: 34px;
        }
        p {
          font-size: 24px;
        }
      }
    }
  }

  .order-list {
    .order-header {
      margin-bottom: 10px;
      h2 {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
  .order-table {
    table {
        tbody {
        tr{
            height: 60px;
            td{
                vertical-align: middle;
            }
        }

        .status {
          background-color: #7ace4c;
          padding: 6px 20px;
          border: none;
          color: #fff;
          text-transform: uppercase;
          font-size: 16px;
        }
        .delivery {
          background-color: #f33155;
          padding: 6px 20px;
          border: none;
          color: #fff;
          text-transform: uppercase;
          font-size: 16px;
        }
      }
    }
  }
}
