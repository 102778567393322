#page-qrcode {
  width: 100%;
  .qrcode-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $borderColor;
    h2 {
      font-size: 24px;
      margin-bottom: 0;
    }
  }
  .qrcode-body {
    .qrcode-form {
      label {
        font-size: 20px;
        margin-right: 10px;
      }
      input {
        margin-right: 20px;
      }
      .btn-generate,.btn-download {
        background-color: $primaryColor;
        border: none;
        padding: 6px 20px;
        color: #fff;
        text-transform: uppercase;
        margin-right: 10px;
      }
    }
    .qrcode-list{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        border: 1px solid $borderColor;
        padding: 20px 20px;
        margin-top: 20px;
        text-align: center;
        .block{
            padding-bottom: 20px;
        }
    }
  }
}
