#page-users {
  width: 100%;
  .user-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $borderColor;
    h2 {
      font-size: 24px;
      margin-bottom: 0;
    }
    button {
      background-color: $primaryColor;
      border: none;
      padding: 6px 20px;
      color: #fff;
      text-transform: uppercase;
    }
  }
  .users-table {
    table {
      tbody {
        tr {
          height: 80px;
          td {
            vertical-align: middle;
          }
        }
        // .delete {
        //   background-color: #f33155;
        //   padding: 6px 20px;
        //   border: none;
        //   color: #fff;
        //   text-transform: uppercase;
        //   font-size: 16px;
        // }
      }
    }
  }
}

#add-product-form {
  width: 100%;
  .p-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    #username {
      height: 40px;
      padding: 0px 10px;
      border: 1px solid $borderColor;
      margin-top: 6px;
    }
    #userphone {
      height: 40px;
      padding: 0px 10px;
      border: 1px solid $borderColor;
      margin-top: 6px;
    }
    #useremail {
      // height: 40px;
      padding: 10px 10px;
      border: 1px solid $borderColor;
      margin-top: 6px;
    }
    #userprofession, #usercoins,#useraddress,#userpincode, #userverified{
      // height: 40px;
      padding: 10px 10px;
      border: 1px solid $borderColor;
      margin-top: 6px;
    }
  }
}

#add-product-btn {
  background-color: $primaryColor;
  border: none;
  padding: 6px 20px;
  color: #fff;
  text-transform: uppercase;
}