#sidebar-logo{
    width: 100%;
    border-bottom: 1px solid $borderColor;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 20px;
    img{
        width: 60%;
    }
}

#sidebar-menu{
    width: 100%;
    .menu-card{
        a{
            display: flex;
            align-items: center;
            height: 60px;
            padding: 0px 10px;
            text-decoration: none !important;
            transition: background-color 0.5s ease;
            &:hover{
                background-color: $borderColor;
            }

            svg{
                fill: $textColor;
                font-size: 28px;
            }
            p{
                margin-left: 20px;
                font-size: 18px;
                color: $textColor;
                
            }
        }
        .active{
            background-color: $borderColor;
            transition: background-color 0.5s ease;
        }
    }
}