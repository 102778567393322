@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$primaryColor:#702df5;
$textColor:#000000;
$borderColor:#ddd;
$backgroundColor:#edf1f5;

*{
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}
p{
  margin-bottom: 0 !important;
}

@import "./styles/layout","./styles/sidebar","./styles/home","./styles/product","./styles/users","./styles/order","./styles/qrcode","./styles/login"