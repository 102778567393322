#app-login{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .container{
        justify-content: center;
        display: flex;
        .app-login-form{
            background-color: #edf1f5;
            box-shadow: 0 1px 4px rgba(0,0,0,.16);
            padding: 20px;
            width: 30%;
            .app-login-logo{
                margin-bottom: 30px;
                img{
                    width: 100%;
                }
            }
        }
    }
}