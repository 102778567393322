#app-main{
    width: 100%;
    position: relative;
    display: flex;
    #app-menu{
      width: 15%;
      position: fixed;
      z-index: 1;
      height: 100vh;
      border-right: 1px solid $borderColor;
    }
    #app-content{
      width: 85%;
      margin-left: 15%;
      z-index: 1;
      #app-top{
        width: 100%;
        border-bottom: 1px solid $borderColor;
        z-index: 2;
        height: 60px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .user-name{
          display: flex;
          align-items: center;
          margin-right: 20px;
          svg{
            font-size: 34px;
          }
          p{
            padding-left: 10px;
            font-size: 18px;
          }
        }
        .user-logout{
          margin-right: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
      #app-page{
        padding: 20px;
      }
    }
  }